import React, { useState, useEffect } from "react";
import { ButtonGroup, Modal } from "react-bootstrap";
import { Formik } from "formik";
import * as Yup from "yup";
import Axios from "axios";
import { Spinner } from "react-bootstrap";
import {
  CRUDLayout,
  InputSearch,
  CreateButton,
  DeleteButton,
  UpdateButton,
  ActionButton,
  DataStatus,
  CreateModal,
  UpdateModal,
  DeleteModal,
  Alert,
  Input,
  Pagination,
  SelectSearch,
  TextArea,
  Switch,
  THead,
  TBody,
  ThFixed,
  TdFixed,
  Tr,
  Th,
  Td,
} from "components";
import { HirarkiApi, UnitOrganisasiApi } from "api";
import { TableNumber } from "utilities";

const UnitOrganisasi = ({ setNavbarTitle }) => {
  // Title
  const title = "Unit Organisasi";

  // indikator pemanggilan data sedang dimuat di server
  const [isPageLoading, setIsPageLoading] = useState(true);

  // indikator apakah sedang melakukan searching
  const [isSearching, setIsSearching] = useState(false);

  // menampung value dari search form
  const [searchKey, setSearchKey] = useState("");

  // menangani modal form tambah
  const [isCreateForm, setIsCreateForm] = useState(false);
  // menangani modal form ubah
  const [isUpdateform, setIsUpdateform] = useState(false);
  // menangani modal hapus data
  const [isDeleteData, setIsDeleteData] = useState(false);

  // data jenis anggaran
  const [dataUnitOrganisasi, setDataUnitOrganisasi] = useState([]);
  const [dataHirarki, setDataHirarki] = useState([]);
  const [kode, setKode] = useState("");
  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [dataLength, setDataLength] = useState(10);
  const [dataCount, setDataCount] = useState(0);

  // menampung data yang akan diubah
  const [updateData, setUpdateData] = useState([]);
  // menampung data yang akan dihapus
  const [deleteData, setDeleteData] = useState([]);

  // menampilkan alert
  const [showAlert, setShowAlert] = useState(false);
  // configurasi alert
  const [alertConfig, setAlertConfig] = useState({
    variant: "primary",
    text: "",
    searchKey: "",
  });

  // request data dari server
  const getData = () => {
    setIsPageLoading(true);

    setIsSearching(false);

    // request data ke server
    Axios.all([
      UnitOrganisasiApi.getPage(page, dataLength, searchKey),
      HirarkiApi.getDropdown(),
      UnitOrganisasiApi.kode(),
    ])
      .then(
        Axios.spread((unit, hirarki, kode) => {
          setDataUnitOrganisasi(unit.data.data);
          setDataHirarki(hirarki.data.data);
          setTotalPage(unit.data.total_page);
          setDataCount(unit.data.data_count);
          setKode(kode.data.data);
        })
      )
      .catch(() =>
        setAlertConfig({
          show: true,
          variant: "danger",
          text: "Data gagal dimuat!",
        })
      )
      .finally(() => {
        setIsPageLoading(false);
      });
  };

  // request search data dari server
  const searchData = (e) => {
    // mencegah terjadinya page reload
    e.preventDefault();

    // set loading
    setIsPageLoading(true);

    // nilai dari form search
    const key = searchKey;

    // request data ke server
    Axios.all([UnitOrganisasiApi.search(key), HirarkiApi.getDropdown(), UnitOrganisasiApi.kode()])
      .then(
        Axios.spread((unit, hirarki, kode) => {
          setDataUnitOrganisasi(unit.data.data);
          setDataHirarki(hirarki.data.data);
          setTotalPage(unit.data.total_page);
          setKode(kode.data.data);

          setAlertConfig({
            variant: "primary",
            searchKey: key,
          });
        })
      )
      .catch(() => {
        setAlertConfig({
          variant: "danger",
          text: "Gagal untuk memuat data",
        });
      })
      .finally(() => {
        setPage(1);
        setIsSearching(true);
        setIsPageLoading(false);
        setShowAlert(true);
      });
  };

  // menangani hide/show data
  const changeDataStatus = (status, id) => {
    setIsPageLoading(true);
    setShowAlert(false);

    const value = {
      id_unit_organisasi: id,
    };

    const onLoadedSuccess = () => {
      setIsSearching(false);
      setAlertConfig({
        show: true,
        variant: "primary",
        text: "Ubah status data berhasil",
      });
      setShowAlert(true);
    };

    const onLoadedFailed = () => {
      setIsSearching(false);
      setAlertConfig({
        show: true,
        variant: "danger",
        text: "Ubah status data gagal",
      });
      setShowAlert(true);
    };

    status === true
      ? UnitOrganisasiApi.show(value)
          .then(() => onLoadedSuccess())
          .catch(() => onLoadedFailed())
          .finally(() => getData())
      : UnitOrganisasiApi.hide(value)
          .then(() => onLoadedSuccess())
          .catch(() => onLoadedFailed())
          .finally(() => getData());
  };

  useEffect(() => {
    // set judul di Navbar
    setNavbarTitle(title);

    // jalankan function request data ke server
    getData();

    // menangani pembersihan saat fetching data
    return () => {
      setIsPageLoading(false);
    };
  }, [setNavbarTitle, page, dataLength, searchKey]);

  // modal tambah
  const TambahModal = () => {
    // data parent unit organisasi
    const [dataParent, setDataParent] = useState([]);

    // loading saat fetch data parent
    const [isPageLoadingDataParent, setIsPageLoadingDataParent] = useState(false);

    const getDataParent = (level) => {
      setIsPageLoadingDataParent(true);

      UnitOrganisasiApi.getParent(level)
        .then((res) => setDataParent(res.data.data))
        .catch((err) => alert(err))
        .finally(() => setIsPageLoadingDataParent(false));
    };

    // nilai awal form
    const formInitialValues = {
      kode_unit_organisasi: kode,
      nama_unit_organisasi: "",
      id_hirarki_unit_organisasi: "",
      id_unit_organisasi_parent: null,
      keterangan: "",
    };

    // skema validasi form
    const formValidationSchema = Yup.object().shape({
      kode_unit_organisasi: Yup.string().required("Masukan kode unit organisasi"),
      nama_unit_organisasi: Yup.string().required("Masukan nama unit organisasi"),
      id_hirarki_unit_organisasi: Yup.string().required("Pilih hirarki unit organisasi"),
    });

    // request tambah data ke server
    const formSubmitHandler = (values) => {
      UnitOrganisasiApi.create(values)
        .then(() => {
          // konfigurasi alert
          setAlertConfig({
            show: true,
            variant: "primary",
            text: "Tambah data berhasil!",
          });
        })
        .catch(() => {
          // konfigurasi alert
          setAlertConfig({
            show: true,
            variant: "danger",
            text: `Tambah data gagal!`,
          });
        })
        .finally(() => {
          // menutup modal
          setIsCreateForm(false);
          // menampilkan alert
          setShowAlert(true);
          // request data baru ke server
          getData();
        });
    };

    return (
      <CreateModal show={isCreateForm} onHide={() => setIsCreateForm(false)} title={title}>
        <Formik
          initialValues={formInitialValues}
          validationSchema={formValidationSchema}
          onSubmit={formSubmitHandler}
        >
          {({
            values,
            errors,
            touched,
            isSubmitting,
            setFieldValue,
            handleChange,
            handleSubmit,
          }) => (
            <form onSubmit={handleSubmit}>
              <Modal.Body>
                <Input
                  label="Kode Unit Organisasi"
                  placeholder="Masukan unit organisasi"
                  name="kode_unit_organisasi"
                  value={values.kode_unit_organisasi}
                  error={errors.kode_unit_organisasi && touched.kode_unit_organisasi && true}
                  errorText={errors.kode_unit_organisasi}
                  onChange={handleChange}
                  readOnly={true}
                />
                <Input
                  label="Nama Unit Organisasi"
                  placeholder="Masukan unit organisasi"
                  name="nama_unit_organisasi"
                  value={values.nama_unit_organisasi}
                  error={errors.nama_unit_organisasi && touched.nama_unit_organisasi && true}
                  errorText={errors.nama_unit_organisasi}
                  onChange={handleChange}
                />
                <SelectSearch
                  label="Hirarki Unit Organisasi"
                  name="id_hirarki_unit_organisasi"
                  placeholder="Pilih hirarki unit organisasi"
                  onChange={(val) => {
                    const { level, id } = val;
                    setFieldValue("id_hirarki_unit_organisasi", id);
                    setFieldValue("id_unit_organisasi_parent", null);
                    getDataParent(level - 1);
                  }}
                  option={dataHirarki.map((res) => {
                    return {
                      id: res.id_hirarki_unit_organisasi,
                      level: res.level_hirarki_unit_organisasi,
                      value: res.id_hirarki_unit_organisasi,
                      label: res.nama_hirarki_unit_organisasi,
                    };
                  })}
                  error={
                    errors.id_hirarki_unit_organisasi && touched.id_hirarki_unit_organisasi && true
                  }
                  errorText={
                    touched.id_hirarki_unit_organisasi && errors.id_hirarki_unit_organisasi
                  }
                />
                <SelectSearch
                  label="Parent Unit Organisasi"
                  name="id_unit_organisasi_parent"
                  placeholder="Pilih parent unit organisasi"
                  loading={isPageLoadingDataParent}
                  onChange={(val) => {
                    const id = val.value;
                    setFieldValue("id_unit_organisasi_parent", id);
                  }}
                  option={dataParent.map((res) => {
                    return {
                      value: res.id_unit_organisasi,
                      label: res.nama_unit_organisasi,
                    };
                  })}
                />
                <TextArea
                  label="Keterangan"
                  name="keterangan"
                  placeholder="Masukan keterangan"
                  value={values.keterangan}
                  error={errors.keterangan && touched.keterangan && true}
                  errorText={errors.keterangan}
                  onChange={handleChange}
                />
              </Modal.Body>
              <Modal.Footer>
                <ActionButton
                  type="submit"
                  variant="primary"
                  text="Tambah Data"
                  loading={isSubmitting}
                />
              </Modal.Footer>
            </form>
          )}
        </Formik>
      </CreateModal>
    );
  };

  // modal ubah
  const UbahModal = () => {
    // data parent unit organisasi
    const [dataParent, setDataParent] = useState([]);
    const [dataUpdateParent, setDataUpdateParent] = useState({
      Value: updateData.id_unit_organisasi_parent,
      label: updateData.nama_unit_organisasi_parent,
    });

    // loading modal
    const [isModalLoading, setIsModalLoading] = useState(true);

    // loading saat fetch data parent
    const [isPageLoadingDataParent, setIsPageLoadingDataParent] = useState(false);

    const getDataParent = (level) => {
      setIsPageLoadingDataParent(true);

      UnitOrganisasiApi.getParent(level)
        .then((res) => setDataParent(res.data.data))
        .catch((err) => alert(err))
        .finally(() => setIsPageLoadingDataParent(false));
    };

    const initialFetchData = () => {
      updateData.level_hirarki_unit_organisasi &&
        UnitOrganisasiApi.getParent(updateData.level_hirarki_unit_organisasi)
          .then((res) => setDataParent(res.data.data))
          .catch((err) => alert(err))
          .finally(() => setIsModalLoading(false));
    };

    useEffect(() => {
      initialFetchData();

      return () => {
        setIsModalLoading(false);
      };
    }, []);

    // nilai awal form
    const formInitialValues = {
      kode_unit_organisasi: updateData.kode_unit_organisasi,
      nama_unit_organisasi: updateData.nama_unit_organisasi,
      id_hirarki_unit_organisasi: updateData.id_hirarki_unit_organisasi,
      id_unit_organisasi_parent: updateData.id_unit_organisasi_parent,
      keterangan: updateData.keterangan,
    };

    // skema validasi form
    const formValidationSchema = Yup.object().shape({
      kode_unit_organisasi: Yup.string().required("Masukan kode unit organisasi"),
      nama_unit_organisasi: Yup.string().required("Masukan nama unit organisasi"),
      id_hirarki_unit_organisasi: Yup.string().required("Pilih hirarki unit organisasi"),
    });

    // request ubah data ke server
    const formSubmitHandler = (values) => {
      const finalValues = {
        id_unit_organisasi: updateData.id_unit_organisasi,
        ...values,
      };

      UnitOrganisasiApi.update(finalValues)
        .then(() => {
          // konfigurasi alert
          setAlertConfig({
            show: true,
            variant: "primary",
            text: "Ubah data berhasil!",
          });
        })
        .catch(() => {
          // konfigurasi alert
          setAlertConfig({
            show: true,
            variant: "danger",
            text: `Ubah data gagal!`,
          });
        })
        .finally(() => {
          setIsSearching(false);
          // menutup modal
          setIsUpdateform(false);
          // menampilkan alert
          setShowAlert(true);
          // request data baru ke server
          getData();
        });
    };

    return (
      <UpdateModal show={isUpdateform} onHide={() => setIsUpdateform(false)} title={title}>
        {isModalLoading ? (
          <div className="text-center my-5">
            <Spinner variant="success" animation="border" />
          </div>
        ) : (
          <Formik
            initialValues={formInitialValues}
            validationSchema={formValidationSchema}
            onSubmit={formSubmitHandler}
          >
            {({
              values,
              errors,
              touched,
              isSubmitting,
              setFieldValue,
              handleChange,
              handleSubmit,
            }) => (
              <form onSubmit={handleSubmit}>
                <Modal.Body>
                  <Input
                    readOnly={true}
                    label="Kode Unit Organisasi"
                    placeholder="Masukan unit organisasi"
                    name="kode_unit_organisasi"
                    value={values.kode_unit_organisasi}
                    error={errors.kode_unit_organisasi && touched.kode_unit_organisasi && true}
                    errorText={errors.kode_unit_organisasi}
                    onChange={handleChange}
                  />
                  <Input
                    label="Nama Unit Organisasi"
                    placeholder="Masukan unit organisasi"
                    name="nama_unit_organisasi"
                    value={values.nama_unit_organisasi}
                    error={errors.nama_unit_organisasi && touched.nama_unit_organisasi && true}
                    errorText={errors.nama_unit_organisasi}
                    onChange={handleChange}
                  />
                  <SelectSearch
                    label="Hirarki Unit Organisasi"
                    name="id_hirarki_unit_organisasi"
                    placeholder="Pilih hirarki unit organisasi"
                    onChange={(val) => {
                      const { level, id } = val;
                      setFieldValue("id_hirarki_unit_organisasi", id);
                      setFieldValue("id_unit_organisasi_parent", null);
                      setDataUpdateParent({
                        value: "",
                        label: "Pilih parent unit organisasi",
                      });
                      getDataParent(level - 1);
                    }}
                    option={dataHirarki.map((res) => {
                      return {
                        id: res.id_hirarki_unit_organisasi,
                        level: res.level_hirarki_unit_organisasi,
                        value: res.id_hirarki_unit_organisasi,
                        label: res.nama_hirarki_unit_organisasi,
                      };
                    })}
                    defaultValue={{
                      label: updateData.nama_hirarki_unit_organisasi,
                      values: updateData.id_hirarki_unit_organisasi,
                    }}
                    error={
                      errors.id_hirarki_unit_organisasi &&
                      touched.id_hirarki_unit_organisasi &&
                      true
                    }
                    errorText={errors.id_hirarki_unit_organisasi}
                  />
                  <SelectSearch
                    label="Parent Unit Organisasi"
                    name="id_unit_organisasi_parent"
                    placeholder="Pilih parent unit organisasi"
                    loading={isPageLoadingDataParent}
                    onChange={(val) => {
                      const id = val.value;
                      setFieldValue("id_unit_organisasi_parent", id);
                    }}
                    option={
                      dataParent
                        ? dataParent.map((res) => {
                            return {
                              value: res.id_unit_organisasi,
                              label: res.nama_unit_organisasi,
                            };
                          })
                        : []
                    }
                    defaultValue={dataUpdateParent}
                  />
                  <TextArea
                    label="Keterangan"
                    name="keterangan"
                    placeholder="Masukan keterangan"
                    value={values.keterangan}
                    error={errors.keterangan && touched.keterangan && true}
                    errorText={errors.keterangan}
                    onChange={handleChange}
                  />
                </Modal.Body>
                <Modal.Footer>
                  <ActionButton
                    type="submit"
                    variant="success"
                    text="Ubah Data"
                    loading={isSubmitting}
                  />
                </Modal.Footer>
              </form>
            )}
          </Formik>
        )}
      </UpdateModal>
    );
  };

  // modal hapus
  const HapusModal = () => {
    // set id dari data yang ingin dihapus
    const idData = deleteData.id_unit_organisasi;
    const values = { id_unit_organisasi: idData };

    // menangani delete button loading
    const [btnLoading, setBtnLoading] = useState(false);

    // menangani pembersihan saat fetching data
    useEffect(() => {
      return () => {
        setBtnLoading(false);
      };
    }, []);

    // request hapus data ke server
    const deleteDataHandler = () => {
      // set delete button loading
      setBtnLoading(true);

      UnitOrganisasiApi.delete(values)
        .then(() => {
          // konfigurasi alert
          setAlertConfig({
            show: true,
            variant: "primary",
            text: "Hapus data berhasil!",
          });
        })
        .catch(() => {
          // konfigurasi alert
          setAlertConfig({
            show: true,
            variant: "danger",
            text: `Hapus data gagal!`,
          });
        })
        .finally(() => {
          // menutup modal
          setIsDeleteData(false);
          // menampilkan alert
          setShowAlert(true);
          // request data baru ke server
          getData();
        });
    };

    return (
      <DeleteModal
        show={isDeleteData}
        onHide={() => setIsDeleteData(false)}
        loading={btnLoading}
        onConfirm={deleteDataHandler}
        title={title}
      >
        <span>Kode Unit Organisasi : {deleteData.kode_unit_organisasi}</span>
        <br></br>
        <span>Nama Unit Organisasi : {deleteData.nama_unit_organisasi}</span>
      </DeleteModal>
    );
  };

  // Tabel
  const Table = () => (
    <>
      <CRUDLayout.Table>
        <THead>
          <Tr>
            <ThFixed>No</ThFixed>
            <ThFixed>Aksi</ThFixed>
            <ThFixed>Kode Unit Organisasi</ThFixed>
            <Th>Nama Unit Organisasi</Th>
            <Th>Hirarki</Th>
            <Th>Unit Organisasi Parent</Th>
            <Th>Keterangan</Th>
          </Tr>
        </THead>
        <TBody>
          {dataUnitOrganisasi.map((val, index) => (
            <Tr key={index}>
              <TdFixed textCenter>{TableNumber(page, dataLength, index)}</TdFixed>
              <TdFixed>
                <div className="d-flex justify-content-center">
                  <ButtonGroup size="sm" className="mr-1">
                    <UpdateButton
                      onClick={() => {
                        setUpdateData(val);
                        setIsUpdateform(true);
                      }}
                    />
                    <DeleteButton
                      onClick={() => {
                        setDeleteData(val);
                        setIsDeleteData(true);
                      }}
                    />
                  </ButtonGroup>
                  <Switch
                    id={toString(index + 1)}
                    checked={!val.is_hidden}
                    onChange={() => changeDataStatus(val.is_hidden, val.id_unit_organisasi)}
                  />
                </div>
              </TdFixed>
              <TdFixed>{val.kode_unit_organisasi}</TdFixed>
              <Td>{val.nama_unit_organisasi}</Td>
              <Td>{val.nama_hirarki_unit_organisasi}</Td>
              <Td>{val.nama_unit_organisasi_parent}</Td>
              <Td>{val.keterangan}</Td>
            </Tr>
          ))}
        </TBody>
      </CRUDLayout.Table>
      {!isSearching && (
        <Pagination
          dataLength={dataLength}
          dataNumber={page * dataLength - dataLength + 1}
          dataPage={dataCount < dataLength ? dataCount : page * dataLength}
          dataCount={dataCount}
          onDataLengthChange={(e) => {
            setDataLength(e.target.value);
            setPage(1);
          }}
          currentPage={page}
          totalPage={totalPage}
          onPaginationChange={({ selected }) => setPage(selected + 1)}
          onDataLengthChange={(e) => {
            setPage(1);
            setDataLength(e.target.value);
          }}
        />
      )}
    </>
  );

  return (
    <CRUDLayout>
      {/* head */}
      <CRUDLayout.Head>
        {/* search section */}
        <CRUDLayout.HeadSearchSection>
          <div className="d-flex mb-3">
            <InputSearch
              onChange={(e) => {
                const key = e.target.value
                setSearchKey(key)
                setAlertConfig({
                  show: key ? true : false,
                  variant: 'primary',
                  text: 'Hasil dari pencarian: ' + key
                })
              }}
            />
          </div>
        </CRUDLayout.HeadSearchSection>

        {/* button section */}
        <CRUDLayout.HeadButtonSection>
          <CreateButton onClick={() => setIsCreateForm(true)} />
        </CRUDLayout.HeadButtonSection>
      </CRUDLayout.Head>

      {/* aLert */}
      <Alert
        show={alertConfig.show}
        variant={alertConfig.variant}
        text={alertConfig.text}
        showCloseButton={true}
        onClose={() =>
          setAlertConfig({
            ...alertConfig,
            show: false,
          })
        }
      />

      {/* table section */}
      {
        // cek apakah data sedang dimuat (loading)
        isPageLoading === true ? (
          // loading
          <DataStatus loading={true} text="Memuat data..." />
        ) : // Cek apakah ada data
        dataUnitOrganisasi.length > 0 ? (
          // Ada data
          <Table />
        ) : (
          // Tidak ada data
          <DataStatus text="Tidak ada data" />
        )
      }

      {/* Modal */}
      <TambahModal />
      <UbahModal />
      <HapusModal />
    </CRUDLayout>
  );
};

export default UnitOrganisasi;
