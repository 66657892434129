import React, {
  useState, useEffect
} from 'react'
import {
  Row,
  Col
} from 'react-bootstrap'
import {
  Formik
} from 'formik'
import * as Yup from 'yup'
import {
  Input,
  Select,
  ActionButton,
  Alert,
  SelectSearch
} from '../../../../../components'
import {
  RegistrasiKaryawanApi
} from '../../../../../api'
import axios from 'axios'

const FormDataKepegawaian = ({dataKaryawan, dataStatusKaryawan, dataLokasiKerja, dataJabatan}) => {
  const [showAlert, setShowAlert] = useState({
    show: false,
    text: '',
    variant: 'primary'
  })

  const [formDataPekerja, setFormDataPekerja] = useState({
    jenis: [],
    status: [],
    status_detail: []
  })
  const [jenis, setJenis] = useState([])
  const [status, setStatus] = useState([])
  const [statusDetail, setStatusDetail] = useState([])

  const [isLoading, setIsLoading] = useState({
    status: false,
    status_detail: false
  })
  
  const [formDataLokasi, setFormDataLokasi] = useState({
    lokasi: [],
  });

  const getJenis = () => {
    RegistrasiKaryawanApi.getJenis()
    .then(res => setJenis(res.data.data))
  }

  const getStatus = (value) => {
    setIsLoading({
			status: true,
      status_detail: true
		})

    RegistrasiKaryawanApi.getStatus(value)
    .then(res => setStatus(res.data.data))
    .finally(() => {
      setIsLoading({
        status: false,
        status_detail: false
      })
    })
  }

  const getStatusDetail = (value) => {
    setIsLoading({
      status_detail: true
		})

    RegistrasiKaryawanApi.getStatusDetail(value)
    .then(res => setStatusDetail(res.data.data))
    .finally(() => {
      setIsLoading({
        status_detail: false
      })
    })
  }

  const getDataPegawai = () => {
    axios.all([
      RegistrasiKaryawanApi.getLokasi(),
    ])
      .then(axios.spread((lokasi) => {
        setFormDataLokasi({
          lokasi: lokasi.data.data,
        })
      }))
  }

  useEffect(() => {
    getDataPegawai()
    
    getJenis()
    setTimeout(() => {
      dataKaryawan.id_jenis_pekerja != null && getStatus(dataKaryawan.id_jenis_pekerja)
    }, 500);
    setTimeout(() => {
      dataKaryawan.id_status_pekerja != null && getStatusDetail(dataKaryawan.id_status_pekerja)
    }, 1000);
  }, [])

  const formInitialValues = {
    id_karyawan: dataKaryawan.id_karyawan,
    no_npwp: dataKaryawan.no_npwp,
    nama_lokasi_kerja: dataKaryawan.nama_lokasi_kerja,
    id_lokasi_kerja: dataKaryawan.id_lokasi_kerja,
    tgl_bergabung: dataKaryawan.tgl_bergabung,
    tgl_pengangkatan: dataKaryawan.tgl_pengangkatan,
    id_jenis_pekerja: dataKaryawan.id_jenis_pekerja,
    nama_jenis_pekerja: dataKaryawan.nama_jenis_pekerja,
    id_status_pekerja: dataKaryawan.id_status_pekerja,
    nama_status_pekerja: dataKaryawan.nama_status_pekerja,
    id_status_pekerja_detail: dataKaryawan.id_status_pekerja,
    nama_status_pekerja_detail: dataKaryawan.nama_status_pekerja_detail,
    no_karyawan: dataKaryawan.no_karyawan,
    id_jabatan: dataKaryawan.id_jabatan,
    id_lokasi: dataKaryawan.id_lokasi,
  }

  const formValidationSchema = Yup.object().shape({
    no_npwp: Yup.string()
      .test('checkNPWP', 'Nomor NPWP telah didaftarkan', value => dataKaryawan.no_npwp === value 
      ? true
      : RegistrasiKaryawanApi.checkNPWP(value)
        .then(() => true)
        .catch(() => false)
      ),
    id_jenis_pekerja: Yup.string()
      .required('Pilih Jenis Pekerja'),
    id_status_pekerja: Yup.string()
      .required('Pilih Status Pekerja'),
    id_jabatan: Yup.string()
      .required('Pilih jabatan'),
    tgl_bergabung: Yup.string()
      .required('Pilih tanggal bergabung'),
    tgl_pengangkatan: Yup.string()
      .required('Pilih tanggal pengangkatan'),
    id_lokasi_kerja: Yup.string()
      .required('Pilih Lokasi Kerja'),
  })

  const formSubmitHandler = (values, {setSubmitting}) => {
    RegistrasiKaryawanApi.updateDataKepegawaian(values)
      .then(() => {
        setShowAlert({
          show: true,
          variant: 'primary',
          text: 'Ubah data sukses!'
        })
      }).catch(err => {
        setShowAlert({
          show: true,
          variant: 'danger',
          text: `Ubah data gagal! (${err})`
        })
      })
      .finally(() => setSubmitting(false))
  }

  return (
    <Formik
      initialValues={formInitialValues}
      validationSchema={formValidationSchema}
      onSubmit={formSubmitHandler}
    >
      {({values, errors, touched, isSubmitting, handleChange, handleSubmit, setFieldValue}) => (
        <form
          onSubmit={handleSubmit}
          className="py-2"
        >
          <Alert 
            show={showAlert.show}
            showCloseButton={true}
            text={showAlert.text}
            variant={showAlert.variant}
            onClose={() => setShowAlert({
              ...showAlert,
              show: false
            })}
          />
          <Row>
            <Col lg>
              <Input 
                type="text"
                label="No. NPWP"
                placeholder="Masukan nomor npwp"
                name="no_npwp"
                value={values.no_npwp}
                error={errors.no_npwp && touched.no_npwp && true}
                errorText={errors.no_npwp}
                onChange={handleChange}
              />
            </Col>
            <Col lg>
              <SelectSearch
                label="Jenis Pekerja"
                name="id_jenis_pekerja"
                defaultValue={{
                  label: values.nama_jenis_pekerja ? values.nama_jenis_pekerja : "Pilih Jenis Pekerja",
                  value: values.id_jenis_pekerja ? values.id_jenis_pekerja : "",
                }}
                onChange={(val) => {
                  setFieldValue("id_jenis_pekerja", val.value);
                  getStatus(val.value)
                }}
                option={jenis.map((val) => {
                  return {
                    value: val.id_jenis_pekerja,
                    label: val.nama_jenis_pekerja,
                  };
                })}
                error={errors.id_jenis_pekerja && touched.id_jenis_pekerja && true}
                errorText={
                  errors.id_jenis_pekerja && touched.id_jenis_pekerja && errors.id_jenis_pekerja
                }
              />
            </Col>
          </Row>
          <Row>
            <Col lg>
              <SelectSearch
                label="Status Pekerja"
                name="id_status_pekerja"
                defaultValue={{
                  label: values.nama_status_pekerja ? values.nama_status_pekerja : "Pilih Status Pekerja",
                  value: values.id_status_pekerja ? values.id_status_pekerja : "",
                }}
                onChange={(val) => {
                  setFieldValue("id_status_pekerja", val.value);
                  getStatusDetail(val.value)
                }}
                option={status.map((val) => {
                  return {
                    value: val.id_status_pekerja,
                    label: val.nama_status_pekerja,
                  };
                })}
                error={errors.id_status_pekerja && touched.id_status_pekerja && true}
                errorText={
                  errors.id_status_pekerja && touched.id_status_pekerja && errors.id_status_pekerja
                }
                isDisabled={values.id_jenis_pekerja == null ? true : false}
                loading={isLoading.status}
              />
            </Col>

            <Col lg>
              <SelectSearch
                label="Status Detail Pekerja"
                name="id_status_pekerja_detail"
                defaultValue={{
                  label: values.nama_status_pekerja_detail ? values.nama_status_pekerja_detail : "Pilih Status Detail Pekerja",
                  value: values.id_status_pekerja_detail ? values.id_status_pekerja_detail : "",
                }}
                onChange={(val) => setFieldValue("id_status_pekerja_detail", val.value)}
                option={statusDetail.map((val) => {
                  return {
                    value: val.id_status_pekerja_detail,
                    label: val.nama_status_pekerja_detail,
                  };
                })}
                isDisabled={values.id_status_pekerja == null ? true : false}
                loading={isLoading.status_detail}
              />
            </Col>
          </Row>
          <Row>
            <Col lg>
              <Input 
                type="text"
                label="No. Induk Pegawai"
                name="no_karyawan"
                value={values.no_karyawan}
                readOnly={true}
              />
            </Col>

            <Col lg>
              <Select
                label="Jabatan"
                name="id_jabatan"
                defaultValue={values.id_jabatan}
                error={errors.id_jabatan && touched.id_jabatan && true}
                errorText={errors.id_jabatan}
                onChange={handleChange}
              >
                <option value="">Pilih jabatan</option>
                {dataJabatan.map((val, index) => (
                  <option
                    key={index}
                    value={val.id_jabatan}
                  >
                    {val.nama_jabatan}
                  </option>
                ))}
              </Select>
            </Col>
          </Row>
          <SelectSearch
            label="Lokasi Kerja"
            name="id_lokasi_kerja"
            defaultValue={{
              value: values.id_lokasi_kerja ? values.id_lokasi_kerja : "",
              label: values.nama_lokasi_kerja ? values.nama_lokasi_kerja : "Pilih Lokasi Kerja",
            }}
            onChange={(val) => setFieldValue("id_lokasi_kerja", val.value)}
            option={formDataLokasi.lokasi.map((val) => {
              return {
                value: val.id_lokasi_kerja,
                label: val.nama_lokasi_kerja,
              };
            })}
            error={
              errors.id_lokasi_kerja && touched.id_lokasi_kerja && true
            }
            errorText={
              errors.id_lokasi_kerja &&
              touched.id_lokasi_kerja &&
              errors.id_lokasi_kerja
            }
          />
          <Row>
            <Col lg>
              <Input 
                type="date"
                label="Tanggal Bergabung"
                name="tgl_bergabung"
                value={values.tgl_bergabung}
                error={errors.tgl_bergabung && touched.tgl_bergabung && true}
                errorText={errors.tgl_bergabung}
                onChange={handleChange}
              />
            </Col>
            <Col lg>
              <Input 
                type="date"
                label="Tanggal Pengangkatan"
                name="tgl_pengangkatan"
                value={values.tgl_pengangkatan}
                error={errors.tgl_pengangkatan && touched.tgl_pengangkatan && true}
                errorText={errors.tgl_pengangkatan}
                onChange={handleChange}
              />
            </Col>
          </Row>
          <div className="d-flex justify-content-end mt-2">
            <ActionButton 
              type="submit"
              variant="success"
              text="Ubah Data Pekerja"
              loading={isSubmitting}
            />
          </div>
        </form>
      )}
    </Formik>
  )
}

export default FormDataKepegawaian
