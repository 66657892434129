import {
  // MASTER JABATAN
  Hirarki,
  UnitOrganisasi,
  Pangkat,
  Grade,
  Jabatan,

  // MASTER KARYAWAN
  LokasiKerja,
  JenisKaryawan,
  StatusKaryawan,
  DetailStatusKaryawan,
  RegistrasiKaryawan,
  DetailKaryawan,
  TambahKaryawan,
  UbahKaryawan,

  // MASTER HAK AKSES
  HakAksesAplikasi,
  HakAksesApproval,
  HakAksesApprovalNew,
  HakAksesDivisi,
  HakAksesDashboard,
} from "pages/HumanResource"

/* 
  MENU REQUIREMENTS
    > EXACT = OPTIONAL (TRUE/FALSE)
    > PATH  = REQUIRED
    > PAGE  = REQUIRED
    > HAK   = REQUIRED (HARUS BERBENTUK ARRAY)
*/

export default [
  {
    exact: true,
    path: "/human-resource/master/jabatan/hirarki",
    page: Hirarki,
    hak: ["HRDA_JBT_HIR"],
  },
  {
    exact: true,
    path: "/human-resource/master/jabatan/unit-organisasi",
    page: UnitOrganisasi,
    hak: ["HRDA_JBT_UOR"],
  },
  {
    exact: true,
    path: "/human-resource/master/jabatan/pangkat",
    page: Pangkat,
    hak: ["HRDA_JBT_PAN"],
  },
  {
    exact: true,
    path: "/human-resource/master/jabatan/grade",
    page: Grade,
    hak: ["HRDA_JBT_GRD"],
  },
  {
    exact: true,
    path: "/human-resource/master/jabatan/jabatan",
    page: Jabatan,
    hak: ["HRDA_JBT_JBT"],
  },

  {
    exact: true,
    path: "/human-resource/master/karyawan/lokasi-kerja",
    page: LokasiKerja,
    hak: ["HRDA_KRY_LOK"],
  },
  {
    exact: true,
    path: "/human-resource/master/karyawan/jenis",
    page: JenisKaryawan,
    hak: ["HRDA_KRY_JK", "SUPA"],
  },
  {
    exact: true,
    path: "/human-resource/master/karyawan/status",
    page: StatusKaryawan,
    hak: ["HRDA_KRY_STA", "SUPA"],
  },
  {
    exact: true,
    path: "/human-resource/master/karyawan/detail-status",
    page: DetailStatusKaryawan,
    hak: ["HRDA_KRY_SKD", "SUPA"],
  },
  {
    exact: true,
    path: "/human-resource/master/karyawan/registrasi",
    page: RegistrasiKaryawan,
    hak: ["HRDA_KRY_KRY"],
  },
  {
    exact: true,
    path: "/human-resource/master/karyawan/tambah",
    page: TambahKaryawan,
    hak: ["HRDA_KRY_KRY"],
  },
  {
    exact: true,
    path: "/human-resource/master/karyawan/:id",
    page: DetailKaryawan,
    hak: ["HRDA_KRY_KRY"],
  },
  {
    exact: true,
    path: "/human-resource/master/karyawan/ubah/:id",
    page: UbahKaryawan,
    hak: ["HRDA_KRY_KRY"],
  },

  {
    exact: true,
    path: "/human-resource/master/hak/aplikasi",
    page: HakAksesAplikasi,
    hak: ["HRDA_HAK_APP"],
  },
  {
    exact: true,
    path: "/human-resource/master/hak/approval",
    page: HakAksesApprovalNew,
    hak: ["HRDA_HAK_APR"],
  },
  {
    exact: true,
    path: "/human-resource/master/hak/divisi",
    page: HakAksesDivisi,
    hak: ["HRDA_HAK_DIV"],
  },
  {
    exact: true,
    path: "/human-resource/master/hak/dashboard",
    page: HakAksesDashboard,
    hak: ["HRDA_HAK_DAS"],
  },
]
