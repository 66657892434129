import {
  IoCashOutline,
  IoSpeedometerOutline,
  IoServerOutline,
  IoDocumentTextOutline,
  IoSchoolOutline,
  IoPeopleOutline,
  IoKeyOutline,
  IoCheckboxOutline,
  IoEaselOutline,
  IoInformationOutline,
  IoCalendarOutline,
  IoPersonCircleOutline,
  IoDocumentOutline,
} from "react-icons/io5";

/* 
  MENU REQUIREMENTS
    > TEXT  = REQUIRED
    > LINK  = REQUIRED
    > EXACT = OPTIONAL (TRUE/FALSE)
    > TYPE  = REQUIRED (MENU/DROPDOWN)
    > HAK   = REQUIRED (HARUS BERBENTUK ARRAY)
    > ICON  = OPTIONAL (REACT-ICONS)
*/

export default [
  {
    text: "Dashboard",
    link: "/",
    type: "menu",
    exact: true,
    icon: <IoSpeedometerOutline />,
    hak: ["HRDA"],
  },
  {
    text: "Master Jabatan",
    type: "dropdown",
    icon: <IoSchoolOutline />,
    hak: ["HRDA"],
    menu: [
      {
        text: "Hirarki Unit Organisasi",
        link: "/human-resource/master/jabatan/hirarki",
        hak: ["HRDA", "HRDA_JBT_HIR"],
      },
      {
        text: "Unit Organisasi",
        link: "/human-resource/master/jabatan/unit-organisasi",
        hak: ["HRDA", "HRDA_JBT_UOR"],
      },
      {
        text: "Pangkat",
        link: "/human-resource/master/jabatan/pangkat",
        hak: ["HRDA", "HRDA_JBT_PAN"],
      },
      {
        text: "Grade",
        link: "/human-resource/master/jabatan/grade",
        hak: ["HRDA", "HRDA_JBT_GRD"],
      },
      {
        text: "Jabatan",
        link: "/human-resource/master/jabatan/jabatan",
        hak: ["HRDA", "HRDA_JBT_JAB"],
      },
    ],
  },
  {
    text: "Master Pekerja",
    type: "dropdown",
    icon: <IoPeopleOutline />,
    hak: ["HRDA"],
    menu: [
      {
        text: "Lokasi Kerja",
        link: "/human-resource/master/karyawan/lokasi-kerja",
        hak: ["HRDA", "HRDA_KRY_LOK"],
      },
      {
        text: "Jenis Pekerja",
        link: "/human-resource/master/karyawan/jenis",
        hak: ["HRDA", "SUPA", "HRDA_KRY_JK"],
      },
      {
        text: "Status Pekerja",
        link: "/human-resource/master/karyawan/status",
        hak: ["HRDA", "SUPA", "HRDA_KRY_STA"],
      },
      {
        text: "Detail Status Pekerja",
        link: "/human-resource/master/karyawan/detail-status",
        hak: ["HRDA", "SUPA", "HRDA_KRY_SKD"],
      },
      {
        text: "Pekerja",
        link: "/human-resource/master/karyawan/registrasi",
        hak: ["HRDA", "HRDA_KRY_KRY"],
      },
    ],
  },
  {
    text: "Hak Akses",
    type: "dropdown",
    icon: <IoKeyOutline />,
    hak: ["HRDA"],
    menu: [
      {
        text: "Hak Akses Modul",
        link: "/human-resource/master/hak/divisi",
        hak: ["HRDA", "HRDA_HAK_DIV"],
      },
      {
        text: "Hak Akses Approval",
        link: "/human-resource/master/hak/approval",
        hak: ["HRDA", "HRDA_HAK_APR"],
      },
      {
        text: "Hak Akses Aplikasi",
        link: "/human-resource/master/hak/aplikasi",
        hak: ["HRDA", "HRDA_HAK_APP"],
      },
      {
        text: "Hak Akses Dashboard",
        link: "/human-resource/master/hak/dashboard",
        hak: ["HRDA", "HRDA_HAK_DAS"],
      },
    ],
  },
  {
    text: "Laporan",
    type: "dropdown",
    icon: <IoDocumentOutline />,
    hak: ["HRDA"],
    menu: [],
  },
  {
    text: "Absensi",
    link: "/human-resource/absensi",
    type: "menu",
    exact: true,
    icon: <IoCheckboxOutline />,
    hak: ["HRDA"],
  },
  {
    text: "Training",
    link: "/human-resource/training",
    type: "menu",
    exact: true,
    icon: <IoEaselOutline />,
    hak: ["HRDA"],
  },
  {
    text: "Info Karyawan",
    link: "/human-resource/info-karyawan",
    type: "menu",
    exact: true,
    icon: <IoInformationOutline />,
    hak: ["HRDA"],
  },
  {
    text: "Agenda",
    link: "/human-resource/agenda",
    type: "menu",
    exact: true,
    icon: <IoCalendarOutline />,
    hak: ["HRDA"],
  },
  {
    text: "Profil",
    type: "dropdown",
    icon: <IoPersonCircleOutline />,
    hak: ["ANG"],
    menu: [
      {
        text: "Akun Saya",
        link: "/profil",
        hak: ["ANG"],
      },
      {
        text: "Ubah Akun",
        link: "/profil/ubah",
        hak: ["ANG"],
      },
      {
        text: "Ganti Password",
        link: "/profil/ganti-password",
        hak: ["ANG"],
      },
    ],
  },
];
