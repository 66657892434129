import React, { useState, useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import { IoArrowUpOutline } from "react-icons/io5";
import Axios from "axios";
import { Formik } from "formik";
import * as Yup from "yup";
import {
  SelectSearch,
  Switch,
  ActionButton,
  DataStatus,
  Alert,
  Table,
  THead,
  TBody,
  Tr,
  Th,
  Td,
  ThFixed,
  TdFixed,
} from "../../../../components";
import { HakAksesDivisiApi } from "../../../../api";

const HakAksesDivisi = ({ setNavbarTitle }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [dataTable, setDataTable] = useState("");
  const [idDivisi, setIdDivisi] = useState("");
  const [dataKaryawan, setDataKaryawan] = useState([]);
  const [dataDivisi, setDataDivisi] = useState([]);
  const [dataId, setDataId] = useState([]);
  const [showAlert, setShowAlert] = useState(false);
  const [alertConfig, setAlertConfig] = useState({
    variant: "primary",
    text: "",
  });

  const getData = () => {
    setIsLoading(true);

    // request data ke server
    Axios.all([HakAksesDivisiApi.getDropdown(), HakAksesDivisiApi.getDivisi()])
      .then(
        Axios.spread((karyawan, divisi) => {
          setDataKaryawan(karyawan.data.data);
          setDataDivisi([
            ...divisi.data.data,
            {
              keterangan: "",
              kode_hak_akses: "ALL",
              kode_hak_akses_parent: "",
              nama_hak_akses: "Semua Hak Akses",
              tipe_hak_akses: "divisi",
            },
          ]);
        })
      )
      .catch((err) => alert(err))
      .finally(() => setIsLoading(false));
  };

  useEffect(() => {
    setNavbarTitle("Hak Akses Modul");
    getData();

    return () => setIsLoading(false);
  }, [setNavbarTitle]);

  const MustSelectDataIndicator = () => (
    <div className="d-flex justify-content-center mt-3">
      <IoArrowUpOutline size="18" />
      <h5 className="ml-2">Pilih data terlebih dahulu</h5>
    </div>
  );

  const DivisiTable = () => {
    const [data, setData] = useState([]);
    const [isSwitchChange, setIsSwitchChange] = useState(false);

    const getDataKaryawan = () => {
      HakAksesDivisiApi.get(dataTable, idDivisi)
        .then((res) => {
          setData(res.data.data);
        })
        .catch((err) => alert(err))
        .finally(() => setIsSwitchChange(false));
    };

    const changeStatus = (index) => {
      setIsSwitchChange(true);

      let dataAwal = data.filter((res) => res.tipe_hak_akses === "menu");
      dataAwal[index] = {
        ...dataAwal[index],
        status: !dataAwal[index].status,
      };
      setData(dataAwal);
      setTimeout(() => {
        setIsSwitchChange(false);
      }, 50);
    };

    useEffect(() => {
      getDataKaryawan();
      return setIsSwitchChange(false);
    }, []);

    const formSubmitedHandler = () => {
      const dataKode = data.filter((res) => res.status === true).map((val) => val.kode_hak_akses);

      dataKode.unshift(idDivisi);

      let postData = {
        id_karyawan: dataId.id_karyawan,
        kode_hak_akses: dataKode,
        tipe_hak_akses: "menu,divisi",
      };

      HakAksesDivisiApi.create(postData)
        .then(() => {
          setIsLoading(true);
          setAlertConfig({
            variant: "primary",
            text: `Tambah data berhasil!`,
          });
        })
        .catch((err) => {
          setAlertConfig({
            variant: "danger",
            text: `Tambah data gagal! (${err})`,
          });
        })
        .finally(() => {
          setIsLoading(false);
          setShowAlert(true);
          getData();
        });
    };
    return (
      <>
        <Table>
          <THead>
            <Tr>
              <ThFixed>No.</ThFixed>
              <ThFixed>Aksi</ThFixed>
              <Th>Nama Hak Akses</Th>
            </Tr>
          </THead>
          <TBody>
            {data.map((val, index) => (
              <Tr key={index}>
                <TdFixed>{index + 1}</TdFixed>
                <TdFixed>
                  <Switch
                    checked={val.status ? true : false}
                    onChange={() => changeStatus(index)}
                  />
                </TdFixed>
                <Td>{val.nama_hak_akses}</Td>
              </Tr>
            ))}
          </TBody>
        </Table>
        <div className="d-flex justify-content-end">
          <ActionButton type="button" onClick={formSubmitedHandler} text="Simpan Hak Akses Modul" />
        </div>
      </>
    );
  };
  return (
    <div
      className="p-1"
      style={{
        minHeight: "100vh",
        overflow: "auto",
      }}
    >
      <>
        <Row>
          <Col sm={6} lg={8}>
            <SelectSearch
              label="Karyawan"
              name="id_karyawan"
              placeholder="Pilih karyawan"
              onChange={(val) => {
                // setFieldValue("id_karyawan", val.value);
                // setFieldValue("nama_karyawan", val.label);
                setDataTable(val.value);
                setDataId({
                  ...dataId,
                  id_karyawan: val.value,
                  nama_karyawan: val.label,
                });
                // handleChange(val);
              }}
              option={dataKaryawan.map((val) => {
                return {
                  value: val.id_karyawan,
                  label: val.nama_karyawan,
                };
              })}
              defaultValue=""
            />
          </Col>
          <Col sm={6} lg={4}>
            <SelectSearch
              label="Modul"
              name="kode_hak_akses"
              placeholder="Pilih Modul"
              onChange={(val) => {
                // setFieldValue("id_karyawan", val.value);
                // setFieldValue("nama_karyawan", val.label);
                setIdDivisi(val.value);
                // handleChange(val);
              }}
              option={dataDivisi.map((val) => {
                return {
                  value: val.kode_hak_akses,
                  label: val.nama_hak_akses,
                };
              })}
              defaultValue=""
            />
          </Col>
        </Row>
        {isLoading ? (
          <DataStatus loading={true} text="Memuat..." />
        ) : dataTable && idDivisi ? (
          <>
            <Alert
              show={showAlert}
              showCloseButton={true}
              variant={alertConfig.variant}
              text={alertConfig.text}
              onClose={() => setShowAlert(false)}
            />
            <DivisiTable />
          </>
        ) : (
          <MustSelectDataIndicator />
        )}
      </>
    </div>
  );
};

export default HakAksesDivisi;
