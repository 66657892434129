import Services from "../../../services";

class UnitOrganisasiApi {
  get() {
    return Services.get("/hrda/unit_organisasi");
  }

  getPage(page, dataLength, key) {
    return Services.get(`/hrda/unit_organisasi/page/?per_page=${dataLength}&page=${page}&q=${key}`);
  }

  getDropdown() {
    return Services.get("/hrda/unit_organisasi/dropdown");
  }

  getParent(id) {
    return Services.get("/hrda/unit_organisasi/dropdown?level_hirarki_unit_organisasi=" + id);
  }

  kode() {
    return Services.get("/hrda/unit_organisasi/no_baru");
  }

  search(key) {
    return Services.get("/hrda/unit_organisasi/page/?per_page=10&page=1&q=" + key);
  }

  show(value) {
    return Services.put("/hrda/unit_organisasi/show", value);
  }

  hide(value) {
    return Services.put("/hrda/unit_organisasi/hide", value);
  }

  create(value) {
    return Services.post("/hrda/unit_organisasi", value);
  }

  update(value) {
    return Services.put("/hrda/unit_organisasi", value);
  }

  delete(value) {
    return Services.post("/hrda/unit_organisasi/delete", value);
  }
}

export default new UnitOrganisasiApi();
