import React, {
  useState,
  useEffect
} from 'react'
import {
  useHistory
} from 'react-router-dom'
import {
  Button
} from 'react-bootstrap'
import {
  IoArrowBackOutline
} from 'react-icons/io5'
import Axios from 'axios'
import {
  CRUDLayout,
  BackButton,
  DataStatus,
  Alert
} from '../../../../components'
import {
  RegistrasiKaryawanApi,
  JabatanApi,
  WilayahApi
} from '../../../../api'
import {
  FormDataPribadi,
  FormDataKontak,
  FormDataAlamat,
  FormDataKepegawaian
} from './Tambah'

const TambahKaryawan = ({setNavbarTitle}) => {
  const history = useHistory()
  const [page, setPage] = useState(1)
  const [isLoading, setIsLoading] = useState(false)
  const [dataJabatan, setDataJabatan] = useState([])
  const [dataJenjangPendidikan, setDataJenjangPendidikan] = useState([])
  const [dataProvinsi, setDataProvinsi] = useState([])
  const [dataBank, setDataBank] = useState([])
  const [idKaryawan, setIdKaryawan] = useState('')
  const [formDataKontak, setFormDataKontak] = useState({})
  const [formDataAlamat, setFormDataAlamat] = useState({})
  const [formDataKepegawaian, setFormDataKepegawaian] = useState({})
  const [alertHandler, setAlertHandler] = useState({
    show: false,
    variant: 'primary',
    text: 'Data Pekerja berhasil ditambah'
  })

  // fetch data
  const getInitialData = () => {
    setIsLoading(true)

    Axios.all([
        RegistrasiKaryawanApi.getJenjangPendidikan(),
        JabatanApi.getDropdown(),
        WilayahApi.getProvinsi(),
        RegistrasiKaryawanApi.getBank(),
      ])
      .then(Axios.spread((jenjang, jabatan, provinsi, bank) => {
        setDataJenjangPendidikan(jenjang.data.data)
        setDataJabatan(jabatan.data.data)
        setDataProvinsi(provinsi.data.data)
        setDataBank(bank.data.data)
      }))
      .catch(err => alert(err))
      .finally(() => setIsLoading(false))
  }

  // refresh data form
  const refreshForm = () => {
    setIdKaryawan('')
    setFormDataKontak({})
    setFormDataAlamat({})
    setFormDataKepegawaian({})
    setPage(1)
    setAlertHandler({
      show: true,
      variant: 'primary',
      text: 'Data Pekerja berhasil ditambah'
    })
  }
  
  useEffect(() => {
    setNavbarTitle('Register Pekerja')
    getInitialData()

    return () => {
      setIsLoading(false)
    }
  }, [setNavbarTitle])

  const PageIndicator = () => {
    const PageIndicatorItems = ({number, text, active, onClick}) => (
      <div 
        className="d-flex flex-column justify-content-center align-items-center mx-2" 
        style={{
          width: '80px',
          cursor: onClick ? 'pointer' : 'default'
        }}
        onClick={onClick}
      >
        <div
          className={`d-flex justify-content-center align-items-center mb-2 rounded-pill text-white shadow-sm ${active && 'bg-primary'}`}
          style={{
            height: '30px',
            width: '30px',
            background: !active && '#C1C1C1',
          }}
        >
         {number} 
        </div>
        <small className="text-center">
          {text}
        </small>
      </div>
    )

    return (
      <div 
        className="d-flex justify-content-center align-items-center mb-2"
        style={{position: 'relative'}}
      >
        <div
          className="bg-secondary"
          style={{
            height: '1px',
            width: '280px',
            position: 'absolute',
            marginTop: '-25px',
            opacity: .3,
            zIndex: 1
          }}
        >
        </div>
        <div 
          className="d-flex justify-content-center align-items-center"
          style={{zIndex: 2}}
        >
          <PageIndicatorItems 
            number="1"
            text="Informasi Umum"
            active={page === 1 && true}
          />
          <PageIndicatorItems 
            number="2"
            text="Informasi Tambahan"
            active={page === 2 && true}
          />
        </div>
      </div>
    )
  }

  const Form = () => {
    return (
      <div>
        <PageIndicator />
        <div>
          <hr />
            <div className="d-flex justify-content-between align-items-center mb-3">
              {page === 1 && <b>Informasi Umum</b>}
              {page === 2 && <b>Informasi Tambahan</b>}
              
              <div>
              <Button 
                variant="outline-secondary" 
                size="sm" 
                className="mx-1 mb-1"
                onClick={() => history.goBack()} 
              >
                <IoArrowBackOutline size={20} />
                <span className="ml-2">Kembali Ke Tabel</span>
              </Button>
              </div>
            </div>
          <Alert 
            show={alertHandler.show}
            variant={alertHandler.variant}
            text={alertHandler.text}
            showCloseButton={true}
            onClose={() => setAlertHandler({...alertHandler, show: false})}
          />
        </div>
        <div>
          {page === 1 && <FormDataKepegawaian
            setPage={setPage}
            setIdKaryawan={setIdKaryawan}
            dataJabatan={dataJabatan}
            setAlertHandler={setAlertHandler}
          />}
          {page === 2 && <FormDataAlamat 
            setPage={setPage}
            idKaryawan={idKaryawan}
            dataProvinsi={dataProvinsi}
            formDataAlamat={formDataAlamat}
            setFormDataAlamat={setFormDataAlamat}
            dataJenjangPendidikan={dataJenjangPendidikan}
            dataBank={dataBank}
          />}
        </div>
      </div>
    )
  }

  return (
    <CRUDLayout>
      {isLoading ? (
        <DataStatus 
          loading={true}
          text="Memuat data . . ."
        />
      ) : (
        <Form />
      )}
    </CRUDLayout>
  )
}

export default TambahKaryawan